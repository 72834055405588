







import { GeoEntity } from "@/models/geoEntity";
import { divIcon, Icon, latLng, LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Component, Prop, Vue } from "vue-property-decorator";
import { LIcon, LMap, LMarker, LTileLayer } from "vue2-leaflet";

// this is important for loading the icons
// https://vue2-leaflet.netlify.app/quickstart/#installation
type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

@Component({ components: { LMap, LMarker, LIcon, LTileLayer } })
export default class PartnerMap extends Vue {
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

  // Adding a ref for the LMap component to access the map instance
  mapRef = "leafletMap";

  @Prop({ default: 500 })
  height?: number;

  @Prop()
  coordinates!: GeoEntity | GeoEntity[];

  @Prop()
  colour!: string;

  get style(): string {
    return `width: 100%; height:${this.height}px; z-index:0;`;
  }

  // this is important for styling the marker according to the partner color
  markerHtmlStyles = `
  background-color: ${this.colour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;`;

  icon = divIcon({
    className: "my-custom-pin",
    html: `<span style="${this.markerHtmlStyles}" />`
  });

  get markers() {
    // Check if coordinates is an array
    if (Array.isArray(this.coordinates)) {
      // Map over the array and check if each coordinate has valid lat and lng
      return this.coordinates
        .filter(coord => coord?.lat && coord?.lng) // Only include coordinates with both lat and lng
        .map(coord => latLng(coord.lat, coord.lng)); // Create latLng object for valid coordinates
    }

    // Check if coordinates is a single object and it has valid lat and lng
    if (this.coordinates && this.coordinates.lat && this.coordinates.lng) {
      return [latLng(this.coordinates.lat, this.coordinates.lng)];
    }

    // If no valid coordinates, return an empty array
    return [];
  }

  // Method to calculate the center of the markers
  get center() {
    if (this.markers.length === 0) {
      return latLng(51.505, -0.09); // Default center if no markers
    }

    let totalLat = 0;
    let totalLng = 0;

    // Calculate the total latitudes and longitudes
    this.markers.forEach(marker => {
      totalLat += marker.lat;
      totalLng += marker.lng;
    });

    // Calculate the average latitude and longitude
    const averageLat = totalLat / this.markers.length;
    const averageLng = totalLng / this.markers.length;

    return latLng(averageLat, averageLng);
  }

  /**
   * Calculate the map bounds to show all markers
   */
  get mapBounds() {
    if (this.markers.length === 0) {
      return new LatLngBounds([51.505, -0.09], [51.505, -0.09]); // Default bounds if no markers
    }

    // if only one marker is there adjust zoom level
    if (this.markers.length === 1) {
      const marker = this.markers[0];
      const buffer = 0.05; // Can be adjustet due to required zoomlevel
      return new LatLngBounds([marker.lat - buffer, marker.lng - buffer], [marker.lat + buffer, marker.lng + buffer]);
    }

    /**
     * add bounds for multipl markers
     */
    const bounds = new LatLngBounds(this.markers[0], this.markers[0]);
    this.markers.forEach(marker => {
      bounds.extend(marker);
    });

    return bounds;
  }
}
