















































































































































































































































































import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import { handleError } from "@/lib/utility/handleError";
import { CompanyWithGeoAndDistanceBase } from "@/models/company.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CompanyMapModule } from "@/store/modules/company-map.store";
import { PartnerModule } from "@/store/modules/partner";
import { Map, divIcon, LatLng, latLng, LeafletMouseEvent, popup } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Component, Prop, Vue } from "vue-property-decorator";
import { LControl, LControlScale, LControlZoom, LIcon, LMap, LMarker, LPopup, LTileLayer } from "vue2-leaflet";
import { GeoEntity } from "@/models/geoEntity";
import Tooltip from "@/components/utility/tooltip.vue";

@Component({
  components: { LControlScale, LMap, LMarker, LIcon, LPopup, LTileLayer, LControl, LControlZoom, Tooltip }
})
export default class CompanyMap extends Vue {
  @Prop({ default: "" })
  search!: string;

  @Prop({ default: null })
  filter!: string | null;

  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  mapRef = "leafletMap";

  itemHeight = 125;

  CompanyMapModule = CompanyMapModule;

  isSearchFocus = true;
  searchLocal = "";
  filterLocal: CompanyServiceEnum | string | null = null;

  isLoading = false;
  showResults = false;

  maxDistance = 50;
  distanceOptions: number[] = [10, 20, 50, 100, 250, 1000];

  geo: GeoEntity | null = null;
  selectedCompany: CompanyWithGeoAndDistanceBase | null = null;

  get geoLatLng() {
    if (this.geo) {
      return latLng(this.geo);
    }

    return undefined;
  }

  get companies() {
    return CompanyMapModule.companies;
  }

  get searchLatLng() {
    return this.search.split(", ").map(v => Number(v));
  }

  get searchLatLngIcon() {
    return divIcon({
      className: "my-search-custom-pin",
      html: `
        <span style="
          background-color: grey;
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 50%;
          border: 2px solid #FFFFFF;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        ">
         <i class="mdi mdi-map-marker-question-outline" style="font-size: 1rem; color: #fff;"></i>
        </span>
      `,
      iconSize: [32, 32],
      iconAnchor: [16, 16]
    });
  }

  selectListItem(company: CompanyWithGeoAndDistanceBase | null) {
    this.selectedCompany = company;
    this.isSearchFocus = false;
    this.$emit("selected", this.selectedCompany);
  }

  onCompanyNameClick(company: CompanyWithGeoAndDistanceBase | null) {
    this.$emit("click", company);
  }

  copyGeo(lat: string, lng: string) {
    const text = `${lat}, ${lng}`;
    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.success("Koordinaten kopiert!");
        })
        .catch(err => {
          this.$toast.error("Fehler beim Kopieren:", err);
        });
    } else {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.$toast.success("Koordinaten kopiert!");
    }
  }

  searchNearby(lat: string, lng: string) {
    this.searchLocal = lat + ", " + lng;
    this.getSearchResults();
  }

  clearSearch() {
    this.showResults = false;
    this.searchLocal = "";
    CompanyMapModule.resetSearch();
  }

  /**
   * possible filter options
   */
  get serviceChips(): { value: string | CompanyServiceEnum; text: string; filter: MrfiktivPageFilterElementGen }[] {
    return [
      {
        value: CompanyServiceEnum.WORKSHOP,
        text: "Werkstätten",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.WORKSHOP }
      },
      {
        value: CompanyServiceEnum.LAWYER,
        text: "Rechtsanwalt",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.LAWYER }
      },
      {
        value: CompanyServiceEnum.APPRAISER,
        text: "Gutachter",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.APPRAISER }
      },
      {
        value: CompanyServiceEnum.RENTAL,
        text: "Vermietung",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.RENTAL }
      },
      {
        value: CompanyServiceEnum.INSURANCE,
        text: "Versicherung",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.INSURANCE }
      },
      {
        value: CompanyServiceEnum.TOWING,
        text: "Abschleppdienst",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.TOWING }
      },
      {
        value: CompanyServiceEnum.LEASING,
        text: "Leasing",
        filter: { key: "services", operation: "$eq", value: CompanyServiceEnum.LEASING }
      },
      {
        value: "isFleet",
        text: "Flottenkunde",
        filter: { key: "isFleet", operation: "$eq", value: "true" }
      }
    ];
  }

  /**
   * Applied filter
   */
  get filterValue(): MrfiktivPageFilterElementGen[] {
    const filterObj = this.serviceChips.find(f => f.value === this.filterLocal);
    return filterObj ? [filterObj.filter] : [];
  }

  /**
   * searchLocal radius
   */
  get searchResultRadius() {
    return CompanyMapModule.distance;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  /**
   * background style of the searchresults
   */
  get background() {
    if (this.isMobile) {
      return this.isSearchFocus ? "" : "transparent";
    }

    return this.showResults ? "" : "transparent";
  }

  windowHeight: number = window.innerHeight;

  onResize() {
    this.windowHeight = window.innerHeight;
  }

  get height() {
    const headerHeight = 64;

    const height = this.windowHeight - headerHeight;
    return height - 102 - 64;
  }

  /**
   * get icon and calculates the style, e.g. selected icon
   */
  icon(company?: CompanyWithGeoAndDistanceBase) {
    let iconHtml = "";
    if (company?.mapIcon?.icon) {
      iconHtml = `<i class="mdi ${company.mapIcon.icon}" style="font-size: 1rem; color: #fff;"></i>`;
    }
    const isSelected =
      this.selectedCompany &&
      company &&
      ((this.selectedCompany as any).id === company.id || (this.selectedCompany as any).id === company.id);
    return divIcon({
      className: `my-custom-pin ${isSelected ? "selected-marker" : ""}`,
      html: `
        <span style="
          background-color: ${isSelected ? "#FF0000" : company?.mapIcon?.color}; ;
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 50%;
          border: 2px solid ${isSelected ? "#FF0000" : "#FFFFFF"};
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        ">
          ${iconHtml}
        </span>
      `,
      iconSize: [32, 32],
      iconAnchor: [16, 16]
    });
  }

  get map(): Map {
    let mapComponentRef = this.$refs.leafletMap;

    if (Array.isArray(mapComponentRef)) {
      mapComponentRef = mapComponentRef[0];
    }

    const mapComponent = (mapComponentRef as unknown) as { mapObject: Map };
    if (!mapComponent || !mapComponent.mapObject) {
      throw new Error("Map not found");
    }
    return mapComponent.mapObject;
  }

  /**
   * calculate map center
   * shift center 200 px to right if result list is shown
   */
  get center() {
    let baseCenter: LatLng;
    if (CompanyMapModule.geoCode?.lat && CompanyMapModule.geoCode?.lng) {
      baseCenter = latLng(CompanyMapModule.geoCode.lat, CompanyMapModule.geoCode.lng);
    } else if (PartnerModule.partner.address?.geo?.lat && PartnerModule.partner.address?.geo?.lng) {
      baseCenter = latLng(PartnerModule.partner.address.geo.lat, PartnerModule.partner.address.geo.lng);
    } else {
      baseCenter = latLng(51.505, -0.09);
    }

    if (this.isMobile) {
      return baseCenter;
    }

    if (this.showResults) {
      const zoom = this.map.getZoom();
      const currentCenterPoint = this.map.project(baseCenter, zoom);
      const offsetX = -200;
      const newCenterPoint = currentCenterPoint.add([offsetX, 0]);
      baseCenter = this.map.unproject(newCenterPoint, zoom);
    }
    return baseCenter;
  }

  /**
   * get zoomlevel based on serachradius
   */
  get computedZoom(): number {
    const containerWidth = window.innerWidth || 800;
    const lat = this.center.lat;
    const radiusMeters = (this.searchResultRadius || this.maxDistance) * 1000;
    const zoomOutFactor = this.isMobile ? 0.25 : 1.5;
    const effectiveRadius = radiusMeters * zoomOutFactor;
    const metersPerPixelAtZoom0 = 156543.03392 * Math.cos((lat * Math.PI) / 180);
    const zoomLevel = Math.log2(((containerWidth / 2) * metersPerPixelAtZoom0) / effectiveRadius);
    return Math.floor(zoomLevel);
  }

  /**
   * change searchLocal radius an aplly backendserach
   * @param option
   */
  async selectRadius(option: number) {
    this.maxDistance = option;
    await this.getSearchResults();
  }

  /**
   * apply backendsearch
   */
  async getSearchResults() {
    if (this.searchLocal === "") {
      return;
    }
    try {
      this.isLoading = true;
      const res = await CompanyMapModule.findCompanies({
        partnerId: PartnerModule?.partner?._id || PartnerModule?.partner?.id,
        search: this.searchLocal,
        maxDistance: this.maxDistance,
        filter: this.filterValue
      });

      this.geo = res.geo;
      this.selectedCompany = null;
      this.showResults = true;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Select company and scroll
   * @param company
   */
  onMarkerClick(company: CompanyWithGeoAndDistanceBase) {
    this.selectedCompany = company;

    let virtualScrollRef = this.$refs.virtualScroll;
    if (Array.isArray(virtualScrollRef)) {
      virtualScrollRef = virtualScrollRef[0];
    }

    const virtualScroll = this.$refs.virtualScroll as Vue & { $el: HTMLElement };
    if (virtualScroll) {
      const index = this.companies.findIndex(value => value.id === company.id);
      if (index) {
        const scrollTo = this.itemHeight * index;

        virtualScroll.$el.scrollTop = scrollTo;
      }
    }
  }

  /**
   * Initialize context menu
   * if search is given, search for location
   */
  async mounted() {
    this.initContextMenu();

    this.searchLocal = this.search;
    this.filterLocal = this.filter;

    if (this.searchLocal) {
      await this.getSearchResults();
    }
  }

  private initContextMenu() {
    this.map.options.tap = true;

    this.map.on("contextmenu", (e: LeafletMouseEvent) => {
      const lat = e.latlng.lat.toFixed(5);
      const lng = e.latlng.lng.toFixed(5);
      const contextPopup = popup({
        className: "my-contextmenu-popup",
        closeButton: false,
        autoClose: true,
        closeOnClick: false,
        offset: [0, 20]
      });
      contextPopup.on("add", () => {
        const menuEl = document.getElementById("leafletContextMenu");
        if (!menuEl) return;
        const copyBtn = menuEl.querySelector("#copyCoordBtn");
        copyBtn?.addEventListener("click", () => {
          this.copyGeo(lat, lng);
          this.map.closePopup(contextPopup);
        });
        const searchBtn = menuEl.querySelector("#searchAreaBtn");
        searchBtn?.addEventListener("click", () => {
          this.searchNearby(lat, lng);
          this.map.closePopup(contextPopup);
        });
      });
      contextPopup
        .setLatLng(e.latlng)
        .setContent(
          `
            <div class="v-card v-card--flat v-sheet theme--light" id="leafletContextMenu">
              <div class="v-card__title text-caption ">
                ${lat}, ${lng}
              </div>
              <div  class="v-card__text">
                <ul style="margin:0; padding:0; list-style:none;">
                  <li id="copyCoordBtn" style="padding:6px 0; cursor:pointer;">Koordinate kopieren</li>
                  <li id="searchAreaBtn" style="padding:6px 0; cursor:pointer;">Im Umkreis suchen</li>
                </ul>
              </div>
            </div>
          `
        )
        .openOn(this.map);
      this.map.once("click", () => {
        this.map.closePopup(contextPopup);
      });
    });
  }

  copyToClipboard(text?: string) {
    if (!text) {
      return;
    }
    navigator.clipboard.writeText(text);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }
}
