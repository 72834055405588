/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { CompanyWithGeoAndDistanceBase } from "@/models/company.entity";
import { IGeocode } from "@/models/User/IAddress";
import companyService from "@/services/mrfiktiv/services/companyService";
import { MrfiktivCompanyControllerFindByGeocodeParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

/**
 * Store for finding partner links
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "company-map",
  store
})
export class CompanyMapStore extends VuexModule {
  _companies: CompanyWithGeoAndDistanceBase[] = [];

  _distance?: number = undefined;
  _geoCode?: IGeocode = undefined;

  get distance() {
    return this._distance;
  }

  get geoCode() {
    return this._geoCode;
  }

  get companies() {
    return this._companies.filter(
      (company): company is CompanyWithGeoAndDistanceBase =>
        company.address !== undefined &&
        company.address.geo !== undefined &&
        company.address.geo.lat !== undefined &&
        company.address.geo.lng !== undefined
    );
  }

  /**
   * Finds partners by string
   */
  @Action
  async findCompanies(query: MrfiktivCompanyControllerFindByGeocodeParamsGen) {
    const res = await companyService.findByGeocode(query);

    const companies: CompanyWithGeoAndDistanceBase[] = [];
    for (const company of res.data) {
      companies.push(new CompanyWithGeoAndDistanceBase(company));
    }

    this.context.commit("_mutateCompanies", companies);
    this.context.commit("_mutateDistance", res.distance);
    this.context.commit("_mutategeo", res.geo);

    return res;
  }

  @Action
  resetSearch() {
    this.context.commit("_mutateCompanies", []);
  }

  /**
   * Mutates the vuex property
   */
  @Mutation
  private _mutateCompanies(companies: CompanyWithGeoAndDistanceBase[]) {
    this._companies = companies;
  }

  /**
   * Mutates the vuex property
   */
  @Mutation
  private _mutateDistance(distance: number) {
    this._distance = distance;
  }

  /**
   * Mutates the vuex property
   */
  @Mutation
  private _mutategeo(geo: IGeocode) {
    this._geoCode = geo;
  }
}

export const CompanyMapModule = getModule(CompanyMapStore);
